import React from "react";
import "../Styles/Loader.css";


function Loader() {
    return <div className="loader d-flex justify-content-center align-items-center">
            <span className="large-circle d-flex justify-content-center align-items-center">
                <span className="small-circle"></span>
            </span>
    </div>;
}

export default Loader;