import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../../Styles/Services.css";

import s1 from "../../Assets/images/s1.jpg";
import s2 from "../../Assets/images/s2.jpg";
import s3 from "../../Assets/images/s3.jpg";
import s4 from "../../Assets/images/s4.jpg";
import s5 from "../../Assets/images/s5.jpg";
import s6 from "../../Assets/images/s6.jpg";

gsap.registerPlugin(ScrollTrigger);

function About() {

    useEffect(() => {
        gsap.to('.services-page', {
            duration: 1,
            opacity: 1,
            ease: 'power3.inOut'
        })
        document.querySelector('.navbar').style.background = "#000";
        gsap.utils.toArray('.service-text').forEach(section => {
            gsap.to(section, 0, {
                opacity: 0,
                x: -90,
                scale: .9
            })
            gsap.to(section, .7, {
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    end: 'bottom 25%'
                },
                opacity: 1,
                x: 0,
                scale: 1,
            });
        });

        gsap.utils.toArray('.service-img').forEach(section => {
            gsap.to(section, 0, {
                opacity: 0,
                x: 90,
                scale: .9
            })
            gsap.to(section, .7, {
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    end: 'bottom 25%'
                },
                opacity: 1,
                x: 0,
                scale: 1,
                delay: .2
            });
        });
    }, []);


    return <div className="services-page container mt-5">

        <div className="row mt-5 mb-5 pt-5 pb-5  service-block">
            <div className="col-md-6 d-flex flex-column justify-content-center service-text">
                <h1>HARDWARE PROVIDER</h1>
                <p>
                    ICONS is a licensed reseller for several leading brands of IT hardware. Using our
                    knowledge and experience we bring cost effective hardware infrastructures that meet our
                    customers current and future needs.
                </p>
            </div>
            <div className="col-md-6 service-img">
                <img alt="about" src={s1} />
            </div>
        </div>

        <div className="row mt-5 mb-5 pt-5 pb-5 about-block">
            <div className="col-md-6 order-md-2 d-flex flex-column justify-content-center service-text">
                <h1>LICENSES & VENDOR SUPPORT PACKAGES</h1>
                <p>
                    ICONS take care of all their customer needs; whether there is a need to patch your
                    OS to the latest release in order to secure your business applications, upgrade your hardware
                    firmware in order to ensure your uptime or even replace a failed disk. We will ensure the rapid
                    supply between your existing or new hardware and our partner vendors.
                </p>
            </div>
            <div className="col-md-6 order-md-1 service-img">
                <img alt="about" src={s2} />
            </div>
        </div>


        <div className="row mt-5 mb-5 pt-5 pb-5 about-block">
            <div className="col-md-6 d-flex flex-column justify-content-center service-text">
                <h1>CONSULTANCY SERVICES</h1>
                <p>
                    ICONS will act as your technology partner and advisor; our strategic IT consulting
                    will create and follow execution of an effective IT strategy for technology transformation of
                    your organization aligned with your business objectives. Our Consultancy services can be System
                    Integration, Strategy & Roadmap, IT Managed Services, Customized Software, IT Digital
                    Transformation, Operation Cost Reduction, Information & System Security.
                    Acting as your technology and partner, our strategic IT consulting create and follow execution
                    of an effective IT strategy for technology transformation of your organization aligned with your
                    business objectives.
                    Improve your IT department architecture using proactive mapped implementation to create a
                    tech-driven digital strategy, and automated operations.
                </p>
            </div>
            <div className="col-md-6 service-img">
                <img alt="about" src={s3} />
            </div>
        </div>

        <div className="row mt-5 mb-5 pt-5 pb-5 about-block">
            <div className="col-md-6 order-md-2 d-flex flex-column justify-content-center service-text">
                <h1>IT Platform Management, Operations & Support</h1>
                <p>
                    ICONS will ensure data center assets whether physical or virtual are optimized to
                    provide maximum uptime for your organization target IT environment, utilizing state of art tools
                    and practice that meet standards and SLAs. Server, Network & Storage Installations,
                    Configuration, Security Hardening, Preventive Maintenance, OS Patching and Scheduling, Hardware
                    Firmware Upgrades, Change Management, Planned Activities and many other services are equipped
                    when our services are utilized for your organizations.
                </p>
            </div>
            <div className="col-md-6 order-md-1 service-img">
                <img alt="about" src={s4} />
            </div>
        </div>

        <div className="row mt-5 mb-5 pt-5 pb-5 about-block">
            <div className="col-md-6 d-flex flex-column justify-content-center service-text">
                <h1>DATA & SYSTEMS MIGRATION SERVICES</h1>
                <p>
                    ICONS understand the importunity behind transferring the entire business data to a
                    new network, our expert engineers follow a structured and efficient approach to implement data
                    migration between different storage and technological infrastructure with almost zero-time down.
                    Migration services include Data Migration, Software Migration, Server Migration and Quality
                    Assurance.
                </p>
            </div>
            <div className="col-md-6 service-img">
                <img alt="about" src={s5} />
            </div>
        </div>

        <div className="row mt-5 mb-5 pt-5 pb-5 about-block">
            <div className="col-md-6 order-md-2 d-flex flex-column justify-content-center service-text">
                <h1>CLOUD SOLUTIONS</h1>
                <p>
                    From public or private cloud, hybrid infrastructure solutions to collaborative cloud
                    solutions. ICONS transform your IT environment and reduces IT resource requirements and improve
                    productivity, in addition to lowering costs and reducing the time to market based on your
                    business needs. Cloud solutions can be virtualized, enterprise software, DR and replication
                    solutions, virtual desktop infrastructure, backup and availability, Windows, Linux & UNIX – and
                    Hyper-Converged Infrastructure Servers.
                </p>
            </div>
            <div className="col-md-6 order-md-1 service-img">
                <img alt="about" src={s6} />
            </div>
        </div>

    </div>;
}

export default About;