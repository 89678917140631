import React, { useEffect, useState } from "react";
import emailjs from 'emailjs-com';
import gsap from "gsap";

import Loader from "../Loader";

function Contact() {

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        gsap.to('.contact-page', {
            duration: 1,
            opacity: 1,
            ease: 'power3.inOut'
        })
        document.querySelector('.navbar').style.background = "#000";
    }, []);

    function sendEmail(e) {
        setLoading(true);
        e.preventDefault();

        emailjs.sendForm('service_cd388bu', 'template_dd917kj', e.target, 'user_5hw4zyCKW9qA7DXWAQid4')
            .then((result) => {
                window.location.reload(false);
            }, (error) => {
                setLoading(false);
            });
    }

    return <>
        { loading ? <Loader /> : < div className="contact-page container mt-5 pt-5" >
            <h1>GET IN TOUCH</h1>
            <p>
                Interested in one of our IT professional and related services and solutions? Get in touch with ICONS today and one of our professional team members will contact you.
            </p>

            <form onSubmit={sendEmail}>

                <div className="form-row">
                    <div className="col">
                        <label htmlFor="name">Name</label>
                        <input type="text" className="form-control form-control-sm" id="name" name="from_name" required />
                    </div>
                    <div className="col">
                        <label htmlFor="email">Email</label>
                        <input type="email" className="form-control form-control-sm" id="email" name="from_email" required />
                    </div>
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="subject">Subject</label>
                    <select className="form-control form-control-sm" id="subject" name="subject">
                        <option value="service">General Customer Service</option>
                        <option value="suggestions">Suggestions</option>
                        <option value="product">Product Support</option>
                        <option value="other">Other</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea className="form-control form-control-sm" name="message" id="message"
                        rows="5"></textarea>
                </div>
                <button type="submit" className="btn btn-dark">Send Message</button>
            </form>
        </div >
        }
    </>;
}

export default Contact;