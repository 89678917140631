import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "../../Styles/Home/Clients.css";

import c1 from "../../Assets/logos/zain.png";
import c2 from "../../Assets/logos/ericsson.png";
import c4 from "../../Assets/logos/arabbank.png";
import c5 from "../../Assets/logos/nbe.png";

gsap.registerPlugin(ScrollTrigger);

function Clients() {

    useEffect(() => {
        gsap.to('.client', 0, {
            opacity: 0,
            x: 50,
            scale: .9
        })

        gsap.to('.client', .5, {
            scrollTrigger: {
                trigger: '.client',
                start: "top 80%",
                end: 'bottom 25%'
            },
            opacity: 1,
            x: 0,
            scale: 1,
            stagger: .2
        })
    }, [])

    return <div className="clients">
        <div
            className="container d-flex flex-column justify-content-center align-items-center text-center pt-5 pb-5">

            <div className="title">
                <h1>clients</h1>
            </div>

            <div className="row text-center pt-5">

                <span className="client col p-3 m-1">
                    <img src={c1} alt="client" />
                </span>
                <span className="client col p-3 m-1">
                    <img src={c2} alt="client" />
                </span>
                <div className="w-100"></div>
                <span className="client col p-3 m-1">
                    <img src={c4} alt="client" />
                </span>
                <span className="client col p-3 m-1">
                    <img src={c5} alt="client" />
                </span>
            </div>
        </div>
    </div>;
}

export default Clients;