import React, { useState, useEffect, useRef } from "react";
import { NavLink, withRouter } from "react-router-dom";
import gsap from "gsap";
import $ from 'jquery';

import "../../Styles/Header.css";
import logo from "../../Assets/logo.png"

function close() {
    gsap.to('.line2', {
        duration: .2,
        opacity: 0,
    })
    gsap.to('.line1', {
        duration: .4,
        y: "8.9px"
    })
    gsap.to('.line1', {
        delay: .4,
        rotation: 45
    })
    gsap.to('.line3', {
        duration: .4,
        y: "-8.9px"
    })
    gsap.to('.line3', {
        delay: .4,
        rotation: -45
    })
}

function open() {
    gsap.to('.line1', {
        rotation: 0,
        duration: .4,
    })
    gsap.to('.line1', {
        delay: .4,
        y: 0
    })
    gsap.to('.line3', {
        rotation: 0,
        duration: .4,
    })
    gsap.to('.line3', {
        delay: .4,
        y: 0
    })
    gsap.to('.line2', {
        delay: .5,
        duration: .1,
        opacity: 1,
        display: "block"
    })
}

function Header() {

    const navBar = useRef(null)
    const [toggled, setToggled] = useState(false);

    useEffect(() => {
        navBar.current.style.background = "rgba(0,0,0,.5)";
    }, []);

    function toggle(e) {
        if (e.currentTarget.href) {
            let target = e.currentTarget.href.replace('http://localhost:3000', '');
            if (target && target === '/') {
                navBar.current.style.background = "rgba(0,0,0,.5)";
            } else {
                navBar.current.style.background = "#000";
            }
        }

        if (toggled !== false) {
            $('.navbar-collapse').collapse('hide');
            open();
        } else {
            close();
        }
        setToggled(!toggled);
    }

    return <nav ref={navBar} className="navbar navbar-expand-md navbar-dark sticky-top d-flex p-2">
        <span className="navbar-brand d-flex justify-content-center align-items-center">
            <img className="img-fluid" src={logo} alt="logo" />
        </span>
        <button onClick={toggle} className="toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav flex-wrap flex-row ml-auto">
                <li className="nav-item">
                    <NavLink onClick={toggle} className="nav-link" exact activeClassName="active" to="/">Home</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink onClick={toggle} className="nav-link" exact activeClassName="active" to="/about">About Us</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink onClick={toggle} className="nav-link" exact activeClassName="active" to="/services">Services</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink onClick={toggle} className="nav-link" exact activeClassName="active" to="/contact">Contact Us</NavLink>
                </li>
            </ul>
        </div>
    </nav>;
}

export default withRouter(Header);