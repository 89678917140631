import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "../../Styles/Home/Services.css";

gsap.registerPlugin(ScrollTrigger);

function Services() {

    useEffect(() => {
        gsap.utils.toArray('.service').forEach(section => {
            gsap.to(section, 0, {
                opacity: 0,
                y: -50,
                scale: .7
            })
            gsap.to(section, .7, {
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    end: 'bottom 25%'
                },
                opacity: 1,
                y: 0,
                scale: 1,
                stagger: .2
            });
        });
    }, [])

    return <div className="services height-100vh">
        <div
            className="container height-100vh d-flex flex-column justify-content-center align-items-center text-center pt-5 pb-5">

            <div className="title">
                <h1>services</h1>
            </div>

            <div className="row pt-5">

                <div className="col-md-6 mb-5">
                    <div className="service d-flex flex-column justify-content-center align-items-center">
                        <div className="service-svg d-flex justify-content-center align-items-center">
                            <svg width="1.5rem" height="1.5rem" viewBox="0 0 16 16"
                                className="bi bi-code-slash mt-1 mb-1" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                    d="M4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0zm-.999-3.124a.5.5 0 0 1 .33.625l-4 13a.5.5 0 0 1-.955-.294l4-13a.5.5 0 0 1 .625-.33z" />
                            </svg>
                        </div>
                        <h5>Software Supplier</h5>
                        <p>
                            ICONS search for the best value, price, efficiency software that meet customers
                            requirements.
                    </p>
                    </div>
                </div>

                <div className="col-md-6 mb-5">
                    <div className="service d-flex flex-column justify-content-center align-items-center">
                        <div className="service-svg d-flex justify-content-center align-items-center">
                            <svg width="1.5rem" height="1.5rem" viewBox="0 0 16 16" className="bi bi-list-check"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                    d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                            </svg>
                        </div>
                        <h5>Consultancy Services</h5>
                        <p>
                            ICONS services include Consultancy Services, Project Management and Business Process engineering.
                    </p>
                    </div>
                </div>

                <div className="row pt-5">

                    <div className="col-md-6 mb-5">
                        <div className="service d-flex flex-column justify-content-center align-items-center">
                            <div className="service-svg d-flex justify-content-center align-items-center">
                                <svg width="1.5rem" height="1.5rem" viewBox="0 0 16 16" className="bi bi-hdd-network-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                        d="M2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h5.5v3A1.5 1.5 0 0 0 6 11.5H.5a.5.5 0 0 0 0 1H6A1.5 1.5 0 0 0 7.5 14h1a1.5 1.5 0 0 0 1.5-1.5h5.5a.5.5 0 0 0 0-1H10A1.5 1.5 0 0 0 8.5 10V7H14a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm.5 3a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                </svg>
                            </div>
                            <h5>Hardware Provider</h5>
                            <p>
                                ICONS provide hardware equipment in a high technical advice for their customers.
                    </p>
                        </div>
                    </div>

                    <div className="col-md-6 mb-5">
                        <div className="service d-flex flex-column justify-content-center align-items-center">
                            <div className="service-svg d-flex justify-content-center align-items-center">
                                <svg width="1.5rem" height="1.5rem" viewBox="0 0 16 16" className="bi bi-graph-up"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                        d="M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z" />
                                </svg>
                            </div>
                            <h5>ICONS Development Program</h5>
                            <p>
                                IDP seeks to develop human capitals and professional resources through career and development program.
                    </p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>;
}

export default Services;