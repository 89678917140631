import React, { useEffect } from "react";
import gsap from "gsap";
import $ from 'jquery';
import "../../Styles/Home/Intro.css";

import i1 from "../../Assets/Intro/intro1.jpg";
import i2 from "../../Assets/Intro/intro2.jpg";
import i3 from "../../Assets/Intro/intro3.jpg";
import i4 from "../../Assets/Intro/intro4.jpg";
import i5 from "../../Assets/Intro/intro5.jpg";

function Carousel() {

    useEffect(() => {
        const $carousel = $('#introCarousel');
        $carousel.bind('slide.bs.carousel', function (e) {
            gsap.to('.carousel-text', 0, {
                opacity: 0,
                x: 50
            });
            gsap.to('.carousel-text', 1, {
                opacity: 1,
                x: 0,
                delay: .5
            });
        });
        let tl = gsap.timeline({ repeat: -1, ease: 'power3.inOut', delay: 1 })

        tl.to('.intro-text0-h', 0, {
            display: "block"
        })

        tl.to('.intro-text0-h', 2, {
            opacity: 1
        })

        tl.to('.intro-text0-h', 2, {
            x:0
        })

        tl.to('.intro-text0-h', 1, {
            opacity: 0
        })
        tl.to('.intro-text0-h', 0, {
            display: "none"
        })

        // ------------------- Text 1
        tl.to('.intro-text1-h', 0, {
            x: 20,
            display: "block"
        })
        tl.to('.intro-text1-p', 0, {
            x: -20,
            display: "block"
        })
        // fade in
        tl.to('.intro-text1-h', 1, {
            x: 0,
            opacity: 1
        })
        tl.to('.intro-text1-p', 1, {
            x: 0,
            opacity: 1
        })
        // delay
        tl.to('.intro-text1-p', 5, {
            x: 0,
        })
        // fade out
        tl.to('.intro-text1-h', 1, {
            x: 20,
            opacity: 0
        })
        tl.to('.intro-text1-p', 1, {
            x: -20,
            opacity: 0
        }, "-=1")
        // reset
        tl.to(['.intro-text1-h', '.intro-text1-p'], 0, {
            display: "none"
        })

        // ------------------- Text 2
        tl.to('.intro-text2-h', 0, {
            y: 20,
            display: "block"
        })
        tl.to('.intro-text2-p', 0, {
            y: -20,
            display: "block"
        })
        // fade in
        tl.to('.intro-text2-h', 1, {
            y: 0,
            opacity: 1
        })
        tl.to('.intro-text2-p', 1, {
            y: 0,
            opacity: 1
        })
        // delay
        tl.to('.intro-text2-p', 5, {
            x: 0,
        })
        // fade out
        tl.to('.intro-text2-h', 1, {
            y: 20,
            opacity: 0
        })
        tl.to('.intro-text2-p', 1, {
            y: -20,
            opacity: 0
        }, "-=1")
        // reset
        tl.to(['.intro-text2-h', '.intro-text2-p'], 0, {
            display: "none"
        })

        // ------------------- Text 3
        tl.to('.intro-text3-h', 0, {
            y: 20,
            scale: .9,
            display: "block"
        })
        tl.to('.intro-text3-p', 0, {
            x: -20,
            display: "block"
        })
        // fade in
        tl.to('.intro-text3-h', 1, {
            y: 0,
            scale: 1,
            opacity: 1
        })
        tl.to('.intro-text3-p', 1, {
            x: 0,
            opacity: 1
        })
        // delay
        tl.to('.intro-text3-p', 5, {
            x: 0,
        })
        // fade out
        tl.to('.intro-text3-h', 1, {
            y: 20,
            opacity: 0
        })
        tl.to('.intro-text3-p', 1, {
            y: -20,
            opacity: 0
        }, "-=1")
        // reset
        tl.to(['.intro-text3-h', '.intro-text3-p'], 0, {
            display: "none"
        })

        // ------------------- Text 4
        tl.to('.intro-text4-h', 0, {
            x: -20,
            display: "block"
        })
        tl.to('.intro-text4-p', 0, {
            x: 20,
            display: "block"
        })
        // fade in
        tl.to('.intro-text4-h', 1, {
            x: 0,
            opacity: 1
        })
        tl.to('.intro-text4-p', 1, {
            x: 0,
            opacity: 1
        })
        // delay
        tl.to('.intro-text4-p', 5, {
            x: 0,
        })
        // fade out
        tl.to('.intro-text4-h', 1, {
            x: 20,
            opacity: 0
        })
        tl.to('.intro-text4-p', 1, {
            x: -20,
            opacity: 0
        }, "-=1")
        // reset
        tl.to(['.intro-text4-h', '.intro-text4-p'], 0, {
            display: "none"
        })
    }, [])

    return <div id="intro" className="intro">
        <div className="intro-background">
            <div className="intro-img intro1" style={{ backgroundImage: "url(" + i1 + ")" }}></div>
            <div className="intro-img intro2" style={{ backgroundImage: "url(" + i2 + ")" }}></div>
            <div className="intro-img intro3" style={{ backgroundImage: "url(" + i3 + ")" }}></div>
            <div className="intro-img intro4" style={{ backgroundImage: "url(" + i4 + ")" }}></div>
            <div className="intro-img intro5" style={{ backgroundImage: "url(" + i5 + ")" }}></div>
        </div>
        <div className="intro-content d-flex justify-content-center align-items-center text-center">
            <div className="intro-text0">
                <h1 className="intro-text0-h it-h">What is ICONS ?</h1>
            </div>
            <div className="intro-text1">
                <h1 className="intro-text1-h it-h">Consultancy Services</h1>
                <h3 className="intro-text1-p it-p">
                    Consultancy Services, Project Management and Business Process
                    Engineering
                </h3>
            </div>
            <div className="intro-text2">
                <h1 className="intro-text2-h it-h">Platform Management, Operations & Support</h1>
                <h3 className="intro-text2-p it-p">
                    IT Infrastructure, Change Management, and Planned Activities.
                </h3>
            </div>
            <div className="intro-text3">
                <h1 className="intro-text3-h it-h">Software Supplier</h1>
                <h3 className="intro-text3-p it-p">
                    Best value, price, and efficient softwares that meets the requirements.
                </h3>
            </div>
            <div className="intro-text4">
                <h1 className="intro-text4-h it-h">Hardware Provider</h1>
                <h3 className="intro-text4-p it-p">
                    Hardware solutions that improve your IT infrastructure
                </h3>
            </div>
        </div>
    </div>;
}

export default Carousel;