import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../../Styles/About.css";

import a2 from "../../Assets/images/a2.jpg";
import a4 from "../../Assets/images/a4.jpg";

gsap.registerPlugin(ScrollTrigger);

function About() {

    useEffect(() => {
        gsap.to('.about-page', {
            duration: 1,
            opacity: 1,
            ease: 'power3.inOut'
        })
        document.querySelector('.navbar').style.background = "#000";
        gsap.utils.toArray('.about-text').forEach(section => {
            gsap.to(section, 0, {
                opacity: 0,
                x: -90,
                scale: .9
            })
            gsap.to(section, .7, {
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    end: 'bottom 25%'
                },
                opacity: 1,
                x: 0,
                scale: 1,
            });
        });

        gsap.utils.toArray('.about-img').forEach(section => {
            gsap.to(section, 0, {
                opacity: 0,
                x: 90,
                scale: .9
            })
            gsap.to(section, .7, {
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    end: 'bottom 25%'
                },
                opacity: 1,
                x: 0,
                scale: 1,
                delay: .2
            });
        });
    }, []);


    return <div className="about-page container mt-5">

        <div className="row mt-5 mb-5 pt-5 pb-5  about-block">
            <div className="col-md-6 d-flex flex-column justify-content-center about-text">
                <h1>OUR GOALS AND OBJECTIVE</h1>
                <ul>
                    <li>- Developing real Icons.</li>
                    <li>- Developing healthy ICT environment.</li>
                    <li>- Supporting the teamwork culture.</li>
                    <li>- Getting customer satisfaction on ICT services.</li>
                    <li>- Collaborate over several segments.</li>
                </ul>
            </div>
            <div className="col-md-6 about-img">
                <img alt="about" src={a2} />
            </div>
        </div>

        <div className="row mt-5 mb-5 pt-5 pb-5 about-block">
            <div className="col-md-6 order-md-2 d-flex flex-column justify-content-center about-text">
                <p>
                    ICONS help enterprises to assess their current IT Infrastructure and evaluates whether an
                    upgrade is required.
                </p>
                <p>
                    Our strategy includes methodical assessment of the IT infrastructure, anticipating the factors
                    that drive unnecessary complexity followed by an intelligent solution that will help improve
                    infrastructure manageability, efficiency and flexibility of any business. We highly value
                    customer’s confidence
                </p>

            </div>
            <div className="col-md-6 order-md-1 about-img">
                <img alt="about" src={a4} />
            </div>
        </div>




        <div className="row mt-5 mb-5 pt-5 pb-5">
            <div className="cold-flex flex-column justify-content-center about-text about-quote">
                <h3>
                    ICONS Integrated IT Solutions is aligned with leading, tier 1 technology vendors to deliver
                    innovative and flexible core IT infrastructure and managed outsourced services for your changing
                    IT
                    environment.
                    </h3>
            </div>
        </div>

    </div>;
}

export default About;