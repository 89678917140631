import React, { useState, useEffect } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

// Components
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Services from "./Components/Services/Services";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import Loader from "./Components/Loader";

function App() {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 2000)
    }, [])

    return <>
        {loading === false ? (
            <>
                <Router>
                    <Header />
                    <main role="main">
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/about" component={About} />
                            <Route exact path="/services" component={Services} />
                            <Route exact path="/contact" component={Contact} />
                        </Switch>
                    </main>
                    <Footer />
                </Router>
            </>
        ) : (
                <Loader />
            )}
    </>;
}

export default App;
