import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "../../Styles/Home/Partners.css";

import p1 from "../../Assets/logos/oracle.png";
import p2 from "../../Assets/logos/veeam.png";
import p3 from "../../Assets/logos/vmware.png";
import p4 from "../../Assets/logos/microsoft.png";
import p5 from "../../Assets/logos/dellemc.png";
import p6 from "../../Assets/logos/fujitsu.png";
import p7 from "../../Assets/logos/fortinet.png";
import p8 from "../../Assets/logos/apc.png";
import p9 from "../../Assets/logos/hpe.png";
import p10 from "../../Assets/logos/aws.png";
import p11 from "../../Assets/logos/juniper.png";
import p12 from "../../Assets/logos/redhat.png";
import p13 from "../../Assets/logos/suse.png";
import p14 from "../../Assets/logos/epson.png";
import p15 from "../../Assets/logos/sophos.png";
import p16 from "../../Assets/logos/veritas.png";
import p17 from "../../Assets/logos/cisco.png";

gsap.registerPlugin(ScrollTrigger);

function Partners() {

    useEffect(() => {
        gsap.to('.partner', 0, {
            opacity: 0,
            y: 50,
            scale: .9
        })
        
        gsap.to('.partner', .5, {
            scrollTrigger: {
                trigger: '.partner',
                start: "top 80%",
                end: 'bottom 25%'
            },
            opacity: 1,
            y: 0,
            scale: 1,
            stagger: .2
        })
    }, [])

    return <div className="partners">
        <div
            className="container d-flex flex-column justify-content-center align-items-center text-center pt-5 pb-5">

            <div className="title">
                <h1>partners</h1>
            </div>

            <div className="row text-center pt-5">

                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p1} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p2} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p3} alt="client" />
                </span>
                <div className="w-100"></div>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p4} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p5} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p6} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p7} alt="client" />
                </span>
                <div className="w-100"></div>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p8} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p9} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p10} alt="client" />
                </span>
                <div className="w-100"></div>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p11} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p12} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p13} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p14} alt="client" />
                </span>
                <div className="w-100"></div>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p15} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p16} alt="client" />
                </span>
                <span className="partner col p-3 m-1">
                    <img className="img-fluid" src={p17} alt="client" />
                </span>
            </div>

        </div>
    </div>;
}

export default Partners;