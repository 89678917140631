import React, { useEffect } from "react";
import gsap from "gsap";
import Intro from "./Intro";
import About from "./About";
import Services from "./Services";
import Clients from "./Clients";
import Partners from "./Partners";

function Home() {

    useEffect(() => {
        gsap.to('.home', {
            duration: 1,
            opacity: 1,
            ease: 'power3.inOut'
        })
    }, [])


    return <div className="home">
        <Intro />
        <About />
        <Services />
        <Clients />
        <Partners />
    </div>;
}

export default Home;