import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "../../Styles/Home/About.css";

import a1 from "../../Assets/images/a1.jpg";
import a2 from "../../Assets/images/a2.jpg";
import a3 from "../../Assets/images/a3.jpg";

gsap.registerPlugin(ScrollTrigger);

function About() {

    useEffect(() => {
        gsap.utils.toArray('.about-us-block').forEach(section => {
            gsap.to(section, 0, {
                opacity: 0,
                y: -50,
                scale: .7
            })
            gsap.to(section, .7, {
                scrollTrigger: {
                    trigger: section,
                    start: "top 80%",
                    end: 'bottom 25%'
                },
                opacity: 1,
                y: 0,
                scale: 1,
                stagger: .2
            });
        });
    }, [])

    return <div className="about-us height-100vh">
        <div className="container height-100vh d-flex flex-column justify-content-center align-items-center text-center pt-5 pb-5">

            <div className="title">
                <h1>About Us</h1>
            </div>

            <p className="mt-3 mb-3">
                ICONS Integrated IT Solutions is a regional company that specializes in the provision of a wide
                array of IT professional and related services, with a focus on the middle eastern and African
                markets.
            </p>

            <div className="row">
                <div className="col-md-4 mb-5">
                    <div className="about-us-block box">
                        <img className="img-fluid mb-3" src={a1} alt="about-us" />
                        <div className="p-3">
                            <h5>Our Vision</h5>
                            <p>Global ICT consultancy & services firm. Developing values as sun delivers light.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-5">
                    <div className="about-us-block box">
                        <img className="img-fluid mb-3" src={a2} alt="about-us" />
                        <div className="p-3">
                            <h5>Our Mission</h5>
                            <p>Providing ICT consultancy of services at high value, which satisfies individuals & organization objectives.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-5">
                    <div className="about-us-block box">
                        <img className="img-fluid mb-3" src={a3} alt="about-us" />
                        <div className="p-3">
                            <h5>Our Value</h5>
                            <p>Integrity Respect Dedication Proficiency.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>;
}

export default About;