import React from "react";
import "../../Styles/Footer.css";

function Footer() {

    return <div className="footer d-flex pt-5 mt-5 pb-3">
        <div className="container">
            <div className="row">

                <div className="col-md-4">
                    <div className="footer-info">
                        <h1>icons</h1>
                        <p>
                            # 301 - M Ali Bldg, Al Khaleej Road <br />
                            Dubai, UAE<br /><br />
                            <strong>Phone:</strong> +971 422 729 49<br />
                            <strong>Mobile:</strong> +971 55 152 0007<br />
                            <strong>Email:</strong> info@iconsisl.com<br />
                        </p>
                    </div>
                </div>

                <div className="col-md-8">
                    <div className="mapouter"><div className="gmap_canvas"><iframe id="gmap_canvas" title="icons-location" src="https://maps.google.com/maps?q=dubai%20Khaleej%20Road%20&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe></div></div>
                </div>

            </div>
            <div className="row pt-3">
                <div className="col text-center mt-3">
                    <p>&copy; 2020 All rights reserved.</p>
                </div>
            </div>
        </div>
    </div >;
}

export default Footer;